class ModalSignup extends HTMLElement {
  constructor() {
    super()

    this.btnClose = this.querySelector('.js-modal-signup-close')
    this.btnsOpen = document.querySelectorAll('.js-modal-signup-btn')
    this.form = this.querySelector('.js-modal-signup-form')
    this.btnSubmit = this.querySelector('.js-modal-signup-submit')
    this.contentData = this.querySelector('.js-modal-signup-form-data')
    this.contentThank = this.querySelector('.js-modal-signup-thanks')
    this.overlay = this.querySelector('.js-modal-signup-overlay')
    this.triggerCheckbox = this.querySelector('.js-modal-signup-trigger-checkbox')
    this.inputCheckbox = this.querySelector('.js-modal-signup-input-checkbox')
    this.errorCheckbox = this.querySelector('.js-modal-signup-checkbox-error')

    this.overlay && this.btnClose && this.closePopup()
    this.btnsOpen && this.openPopup()
    this.form && this.submitForm()
    this.triggerCheckbox && this.inputCheckbox && this.initCheckbox()
  }
  
  closePopupFn () {
    this.classList.remove('active')
    this.contentData && this.contentData.classList.remove('hidden')
    this.contentThank && this.contentThank.classList.add('hidden')
    document.body.classList.remove('overflow-hidden')
  }

  closePopup () {
    this.btnClose.addEventListener('click', () => {
      this.closePopupFn()
    })
    this.overlay.addEventListener('click', () => {
      this.closePopupFn()
    })
  }

  openPopup () {
    this.btnsOpen.forEach(btnOpen => {
      btnOpen.addEventListener('click',() => {
        const modalId = btnOpen.dataset.modalId
        const modalEl = document.querySelector(modalId)
        if (modalEl) {
          modalEl.classList.add('active')
          document.body.classList.add('overflow-hidden')
        }
      })
    })
  }

  submitForm () {
    this.form.addEventListener('submit', (event) => {
      event.preventDefault()
      if (this.inputCheckbox.checked) {
        let url = this.form.getAttribute('action')
        url = url.replace('/post?u=', '/post-json?u=')
        url += this.serialize(this.form) + '&c=displayMailChimpStatus'

        let ref = window.document.getElementsByTagName( 'script' )[ 0 ]
        let script = window.document.createElement( 'script' )
        script.src = url

        window.mcStatus = this.form.querySelector('.mc-status')

        ref.parentNode.insertBefore( script, ref )

        script.onload = function () {
          this.remove()
        }
      } else {
        this.triggerCheckbox && this.triggerCheckbox.classList.add('error')
        this.errorCheckbox && this.errorCheckbox.classList.add('error')
      }
    })
  }

  serialize (form) {
    let serialized = ''
    for (let i = 0; i < form.elements.length; i++) {
      var field = form.elements[i]
      if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue

      if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
        serialized += '&' + encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value)
      }
    }

    return serialized
  }

  initCheckbox () {
    this.triggerCheckbox.addEventListener('click', () => {
      this.inputCheckbox.checked = !this.inputCheckbox.checked
      if(this.inputCheckbox.checked) {
        this.triggerCheckbox && this.triggerCheckbox.classList.remove('error')
        this.errorCheckbox && this.errorCheckbox.classList.remove('error')
      }
    })

    this.inputCheckbox.addEventListener('change', () => {
      if(this.inputCheckbox.checked) {
        this.triggerCheckbox && this.triggerCheckbox.classList.remove('error')
        this.errorCheckbox && this.errorCheckbox.classList.remove('error')
      }
    })
  }
}

window.displayMailChimpStatus = function (data) {
	if (!data.result || !data.msg || !mcStatus ) return
  
  const modalSignup = document.querySelector('.js-modal-signup.active')
  const contentData = modalSignup?.querySelector('.js-modal-signup-form-data')
  const contentThank = modalSignup?.querySelector('.js-modal-signup-thanks')

	if (data.result === 'error') {
    mcStatus.innerHTML = data.msg.replace(/^\d+\s-\s/gm, '')
		return;
	}

  contentData?.classList.add('hidden')
  contentThank?.classList.remove('hidden')
}

customElements.define('modal-signup', ModalSignup)
